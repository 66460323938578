import axios from 'axios';
import { REST_HOSTNAME } from '../Config';
import { DialogPrompt } from './DialogPromptApi';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export type DialogPlanLlmPromptTypeUnion = DialogPlanLlmPrompt & DialogPlanLlmPromptImage;

export class DialogPlanLlmPrompt {
    // Add an index signature to allow arbitrary string keys at the cost of type safety.
    // [key: string]: any; // Add this index signature
    id: number;
    dialog_plan_id: number;
    order_index: number;
    is_teacher: boolean;
    prompt: string;
    is_note_to_student: boolean;
    class_name: string;
    variable_list?: string[];

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
    ) {
        this.id = id;
        this.dialog_plan_id = dialog_plan_id;
        this.order_index = order_index;
        this.is_teacher = is_teacher;
        this.prompt = prompt;
        this.is_note_to_student = is_note_to_student;
        this.class_name = "DialogPlanLlmPrompt";
    }
}

export class DialogPlanLlmPromptImage extends DialogPlanLlmPrompt {
    image_src: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        image_src: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptImage"
        this.image_src = image_src;
    }
}

export class DialogPlanLlmPromptIntroduceWord extends DialogPlanLlmPrompt {
    new_word: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptIntroduceWord"
        this.new_word = new_word;
    }
}


export class DialogPlanLlmPromptListenSpeak extends DialogPlanLlmPrompt {
    new_word: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptListenSpeak"
        this.new_word = new_word;
        this.yes_response = yes_response
        this.no_response = no_response
    }
}

export class DialogPlanLlmPromptListenSpeakNoGrade extends DialogPlanLlmPrompt {
    new_word: string;
    yes_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        yes_response: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptListenSpeakNoGrade"
        this.new_word = new_word;
        this.yes_response = yes_response
    }
}


export class DialogPlanLlmPromptQuizQuestion extends DialogPlanLlmPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    teacher_chinese_prompt: string;
    correct_vocabulary_word: string | null;
    correct_vocabulary_word_image: string | null;
    incorrect_vocabulary_word_list: string | null;
    incorrect_vocabulary_word_image_list: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        teacher_chinese_prompt: string,
        correct_vocabulary_word: string | null,
        correct_vocabulary_word_image: string | null,
        incorrect_vocabulary_word_list: string | null,
        incorrect_vocabulary_word_image_list: string | null
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student)
        this.class_name = "DialogPlanLlmPromptQuizQuestion"
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.correct_vocabulary_word = correct_vocabulary_word
        this.correct_vocabulary_word_image = correct_vocabulary_word_image
        this.incorrect_vocabulary_word_list = incorrect_vocabulary_word_list
        this.incorrect_vocabulary_word_image_list = incorrect_vocabulary_word_image_list
    }
}

export class DialogPlanLlmPromptQuizWord extends DialogPlanLlmPrompt {
    new_word: string;
    yes_response: string;
    no_response: string;
    wrong_word_list: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        yes_response: string,
        no_response: string,
        wrong_word_list: string | null,
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptQuizWord"
        this.new_word = new_word;
        this.yes_response = yes_response;
        this.no_response = no_response;
        this.wrong_word_list = wrong_word_list
    }
}


export class DialogPlanLlmPromptSpeakNoGrade extends DialogPlanLlmPrompt {
    yes_response: string;
    image_src: string | null;
    teacher_chinese_prompt: string | null;
    student_response_prompt: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        image_src: string | null,
        teacher_chinese_prompt: string | null,
        student_response_prompt: string | null,
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptSpeakNoGrade"
        this.yes_response = yes_response
        this.image_src = image_src
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.student_response_prompt = student_response_prompt
    }
}


export class DialogPlanLlmPromptTypeSpeak extends DialogPlanLlmPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    teacher_chinese_prompt: string | null;
    student_response_prompt: string | null;
    dialog_prompt_sentence_list: string | null;
    teacher_ask_student_to_speak_prompt: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        teacher_chinese_prompt: string | null,
        student_response_prompt: string | null,
        dialog_prompt_sentence_list: string | null,
        teacher_ask_student_to_speak_prompt: string | null,

    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptTypeSpeak"
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.student_response_prompt = student_response_prompt
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
        this.teacher_ask_student_to_speak_prompt = teacher_ask_student_to_speak_prompt
    }
}



export interface IGeneratedDialogPlanLlmPromptResponse {
    dialog_plan_llm_prompt: DialogPlanLlmPrompt;
    dialog_prompt: DialogPrompt;
}

export const DialogPlanLlmPromptApi = {
    getAll: function (dialogPlanId?: number) {
        return axiosInstance.request<DialogPlanLlmPrompt[]>({
            method: "GET",
            url: `/dialog_plan_llm_prompt${dialogPlanId ? `?dialog_plan_id=${dialogPlanId}` : ''}`
        });
    },
    getById: function (dialogPlanLlmPromptId: number) {
        return axiosInstance.request<DialogPlanLlmPrompt>({
            method: "GET",
            url: `/dialog_plan_llm_prompt/${dialogPlanLlmPromptId}`
        });
    },
    create: function (dialogPlanLlmPrompt: DialogPlanLlmPrompt) {
        return axiosInstance.request<DialogPlanLlmPrompt[]>({
            method: "POST",
            url: `/dialog_plan_llm_prompt`,
            data: dialogPlanLlmPrompt
        });
    },
    update: function (dialogPlanLlmPrompt: DialogPlanLlmPrompt) {
        return axiosInstance.request<DialogPlanLlmPrompt[]>({
            method: "PUT",
            url: `/dialog_plan_llm_prompt`,
            data: dialogPlanLlmPrompt
        });
    },
    delete: function (dialogPlanLlmPromptId: number) {
        return axiosInstance.request<DialogPlanLlmPrompt[]>({
            method: "DELETE",
            url: `/dialog_plan_llm_prompt/${dialogPlanLlmPromptId}`,
        });
    },
    generateById: function (hskLevel: number, dialogPlanLlmPromptId: number,
        vocabularyWord1?: string, vocabularyWord2?: string, vocabularyWord3?: string, vocabularyWord4?: string, vocabularyWord5?: string) {
        return axiosInstance.request<IGeneratedDialogPlanLlmPromptResponse>({
            method: "POST",
            url: `/generated_dialog_plan_llm_prompt/${hskLevel}/${dialogPlanLlmPromptId}`,
            data: {
                'dialog_plan_input_dict': {
                    '<HskLevel/>': hskLevel,
                    '<VocabularyWord1/>': vocabularyWord1,
                    '<VocabularyWord2/>': vocabularyWord2,
                    '<VocabularyWord3/>': vocabularyWord3,
                    '<VocabularyWord4/>': vocabularyWord4,
                    '<VocabularyWord5/>': vocabularyWord5,
                }
            }
        });
    },
    generate: function (hskLevel: number, dialogPlanLlmPrompt: DialogPlanLlmPrompt, dialogPrompt?: DialogPrompt, fieldName?: string,
        vocabularyWord1?: string, vocabularyWord2?: string, vocabularyWord3?: string, vocabularyWord4?: string, vocabularyWord5?: string) {
        // Check if exactly one of dialogPrompt or fieldName is defined
        const isDialogPromptDefined = dialogPrompt !== undefined;
        const isFieldNameDefined = fieldName !== undefined;

        if (isDialogPromptDefined !== isFieldNameDefined) {
            throw new Error("Either both dialogPrompt and fieldName should be defined, or both should be undefined.");
        }
        return axiosInstance.request<IGeneratedDialogPlanLlmPromptResponse>({
            method: "POST",
            url: `/generated_dialog_plan_llm_prompt/${hskLevel}`,
            data: {
                'dialog_plan_input_dict': {
                    '<HskLevel/>': hskLevel,
                    '<VocabularyWord1/>': vocabularyWord1,
                    '<VocabularyWord2/>': vocabularyWord2,
                    '<VocabularyWord3/>': vocabularyWord3,
                    '<VocabularyWord4/>': vocabularyWord4,
                    '<VocabularyWord5/>': vocabularyWord5,
                },
                'dialog_plan_llm_prompt': dialogPlanLlmPrompt,
                ...(dialogPrompt && { dialog_prompt: dialogPrompt }), // Conditionally add dialogPrompt if it is defined
                ...(fieldName && { field_name: fieldName }) // Conditionally add fieldName if it is defined
            }
        });
    }
}

export function DialogPlanLlmPromptFactory(className: string,
    id: number,
    dialogPlanId: number,
    orderIndex: number,
    isTeacher: boolean,
    prompt: string,
    isNoteToStudent: boolean,
    imageSource?: string | null,
    newWord?: string | null,
    yesResponse?: string | null,
    noResponse?: string | null,
    sentenceId?: number | null,
): DialogPlanLlmPrompt {
    if (className == "DialogPlanLlmPrompt") {
        const dialogPrompt = new DialogPlanLlmPrompt(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptImage") {
        const dialogPrompt = new DialogPlanLlmPromptImage(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            imageSource ? imageSource : "broken"
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptIntroduceWord") {
        const dialogPrompt = new DialogPlanLlmPromptIntroduceWord(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptListenSpeak") {
        const dialogPrompt = new DialogPlanLlmPromptListenSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptListenSpeakNoGrade") {
        const dialogPrompt = new DialogPlanLlmPromptListenSpeakNoGrade(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptQuizWord") {
        const dialogPrompt = new DialogPlanLlmPromptQuizWord(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "broken",
            noResponse ? noResponse : "broken",
            null
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptQuizQuestion") {
        const dialogPrompt = new DialogPlanLlmPromptQuizQuestion(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "",
            "",
            "",
            "",
            "",
            ""
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptSpeakNoGrade") {
        const dialogPrompt = new DialogPlanLlmPromptSpeakNoGrade(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "",
            null, null
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptTypeSpeak") {
        const dialogPrompt = new DialogPlanLlmPromptTypeSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            imageSource ? imageSource : "",
            "broken", "broken",
            "broken",
            "broken"
        );
        return dialogPrompt
    }
    throw new Error("Unknown dialog prompt class " + className);
}
