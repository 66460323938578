import { Box, Button, Stack, Typography } from '@mui/material';
import { shuffle } from 'lodash'; // Importing the shuffle function from a library like Lodash
import React from 'react';
import { DialogPromptQuizQuestion } from '../../api/DialogPromptApi';
import ChatBubble from '../ChatBubble';


interface DialogPromptQuizQuestionComponentProps {
    dialogPrompt: DialogPromptQuizQuestion;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptQuizQuestionComponent: React.FC<DialogPromptQuizQuestionComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const [componentList, setComponentList] = React.useState<any[]>([]);
    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [chatBubbleCorrectDone, setChatBubbleCorrectDone] = React.useState(false);

    const studentAvatar = localStorage.getItem('studentAvatar');
    // const chatBubbleFont = useKaiTiFontStyles("white", '1.5rem');

    let answerList: any[] = []

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
    }, []);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [componentList])

    React.useEffect(() => {
        if (chatBubbleDone) {

            setChatBubbleDone(false);
            const newComponent: any[] = [];
            if (dialogPrompt.image_src) {
                newComponent.push(<img
                    src={dialogPrompt.image_src}
                    alt={dialogPrompt.prompt}
                    style={{
                        maxHeight: '400px', maxWidth: '400px',
                        height: 'auto', width: 'auto',
                        margin: '0 auto', // Add this line to center the image horizontally
                        display: 'block', // Add this line to ensure the margin works correctly
                    }}
                />)
            }

            if (dialogPrompt.teacher_chinese_prompt != null && dialogPrompt.teacher_chinese_prompt.trim().length > 0) {
                newComponent.push(<ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.teacher_chinese_prompt}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />);
            }
            setComponentList((prevComponentList) => [...prevComponentList, ...newComponent]);
        }

        const allWordList = [...dialogPrompt.incorrect_vocabulary_word_list];
        allWordList.push(dialogPrompt.correct_vocabulary_word);

        const wordAnswerList = allWordList.map((vocabularyWordOption, index) => (
            <Stack key={vocabularyWordOption.id} direction="column" alignItems="center">
                {vocabularyWordOption.word ?
                    <Button
                        variant="contained"
                        onClick={() => handleAnswerClick(vocabularyWordOption.id, vocabularyWordOption.id === dialogPrompt.correct_vocabulary_word.id)}
                    >
                        <Typography style={{ fontFamily: 'KaiTi', fontSize: '24px' }}>
                            {vocabularyWordOption.word}
                        </Typography>
                    </Button>
                    :
                    <>{/* create empty child so foundAnswer.props.children[0] works */}</>
                }
                {vocabularyWordOption.image ?
                    <img
                        src={vocabularyWordOption.image}
                        alt={vocabularyWordOption.word}
                        key={vocabularyWordOption.id}
                        onClick={() => handleAnswerClick(vocabularyWordOption.id, vocabularyWordOption.id == dialogPrompt.correct_vocabulary_word.id)}
                        style={{ maxHeight: '125px', maxWidth: '125px', height: 'auto', width: 'auto' }}
                    />
                    :
                    <>{/* create empty child so foundAnswer.props.children[0] works */}</>
                }
            </Stack>
        ));
        answerList = shuffle(wordAnswerList.filter(Boolean)); // Shuffle the non-null components
        setPromptInputComponent(
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                {answerList}
            </Stack>
        )
    }, [chatBubbleDone])

    const handleAnswerClick = (vocabularyWordId: number, correct: boolean) => {

        const foundAnswer = answerList.find((answer) => answer.key == vocabularyWordId);
        const additionalComponentList: React.ReactNode[] = []
        let foundAnswerChatBubbleCopy = null;
        if (foundAnswer.props.children[0].props.children) {
            foundAnswerChatBubbleCopy =
                <Stack direction="row" alignItems="center" justifyContent="right" spacing={2}>
                    <div style={{ flex: 1, maxWidth: '300px' }}>
                        <ChatBubble
                            isTeacher={false}
                            message={foundAnswer.props.children[0].props.children.props.children}
                            showAvatar={false} // avatar manually added below
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            useChineseFont={true}
                        />
                    </div>
                    <img
                        src={studentAvatar || ''}
                        style={{ maxHeight: '4em', maxWidth: '4em' }}
                    />
                </Stack>
            additionalComponentList.push(foundAnswerChatBubbleCopy)
        }
        if (foundAnswer.props.children[1].props.src) {
            foundAnswerChatBubbleCopy =
                <Stack direction="row" alignItems="center" justifyContent="right" spacing={2}>
                    <img
                        src={foundAnswer.props.children[1].props.src}
                        alt={foundAnswer.props.children[1].props.alt}
                        key={foundAnswer.props.children[1].key}
                        style={{ maxHeight: '200px', maxWidth: '200px', height: 'auto', width: 'auto' }}
                    />
                    {foundAnswerChatBubbleCopy == null && (
                        <img
                            src={studentAvatar || ''}
                            style={{ maxHeight: '4em', maxWidth: '4em' }}
                        />
                    )}
                    {foundAnswerChatBubbleCopy !== null && (
                        <div style={{ width: '4em' }}></div> // Spacer with the same width as the img
                    )}
                </Stack>
            additionalComponentList.push(foundAnswerChatBubbleCopy)
        }
        if (correct) {
            setPromptInputComponent(<></>)

            const newChatBubble = (
                <ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.yes_response}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setChatBubbleCorrectDone}
                />
            );
            setComponentList((prevComponentList) => [...prevComponentList, ...additionalComponentList, newChatBubble]);
        } else {
            answerList = shuffle(answerList.filter(Boolean)); // Shuffle the non-null components
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                    {answerList}
                </Stack>
            )

            const newChatBubble = (
                <ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.no_response}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            );
            setComponentList((prevComponentList) => [...prevComponentList, ...additionalComponentList, newChatBubble]);
        }
    }

    React.useEffect(() => {
        if (chatBubbleCorrectDone) {
            setDialogPromptComplete(dialogPrompt.id)
        }
    }, [chatBubbleCorrectDone])

    return (
        <Stack spacing={2}>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {componentList.map((component, index) => (
                <Box key={index} component="div">
                    {component}
                </Box>
            ))}
        </Stack>
    );
};

export default DialogPromptQuizQuestionComponent;
