import { Button, Stack } from '@mui/material';
import React from 'react';
import { DialogPromptLearnWithCharacter } from '../../api/DialogPromptApi';
import { IUser, UserApi } from '../../api/UserApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';

interface DialogPromptLearnWithCharacterComponentProps {
    dialogPrompt: DialogPromptLearnWithCharacter;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptLearnWithCharacterComponent: React.FC<DialogPromptLearnWithCharacterComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [response, setResponse] = React.useState<string | null>(null);

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [chatBubbleDone2, setChatBubbleDone2] = React.useState(false);

    const handleYesButtonClick = () => {
        localStorage.setItem('learnWithCharacter', "true");
        setResponse(dialogPrompt.yes_response);
        updateDbLearnWithCharacter(true);
    }

    const handleNoButtonClick = () => {
        localStorage.setItem('learnWithCharacter', "false");
        setResponse(dialogPrompt.no_response);
        updateDbLearnWithCharacter(false);
    }

    const updateDbLearnWithCharacter = (learnWithCharacdter: boolean) => {
        const updatedUser: IUser = {
            learn_chinese_character: learnWithCharacdter,
        };
        // Call the update function
        UserApi.update(updatedUser)
            .then(response => {
                // Handle the response
                // console.log('User updated:', response.data);
            })
            .catch(error => {
                // Handle the error
                console.error(error);
                setErrorMessage('Error updating user:' + error.message);
            });
    }

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center' }}  >
                    <Button variant="contained" color="primary" onClick={() => handleYesButtonClick()} sx={{ ml: 4 }}>
                        {dialogPrompt.yes_button_text}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => handleNoButtonClick()} sx={{ ml: 4 }}>
                        {dialogPrompt.no_button_text}
                    </Button>
                </Stack>
            )
        }
    }, [chatBubbleDone]);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    })

    React.useEffect(() => {
        if (chatBubbleDone2) {
            setDialogPromptComplete(dialogPrompt.id)
        }
    }, [chatBubbleDone2])

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {response == null ? null : (
                <ChatBubble
                    isTeacher={!dialogPrompt.is_teacher}
                    message={response}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleDone2}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            )}
        </Stack>
    );
};

export default DialogPromptLearnWithCharacterComponent;
