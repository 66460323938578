import { Button, Stack } from '@mui/material';
import React from 'react';
import { DialogPromptSelectNextDialog } from '../../api/DialogPromptApi';
import { INextDialog } from '../../api/NextDialog';
import { IUser, UserApi } from '../../api/UserApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';

interface DialogPromptSelectNextDialogComponentProps {
    dialogPrompt: DialogPromptSelectNextDialog;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    addDialog: (dialogId: number) => void;
}

const DialogPromptSelectNextDialogComponent: React.FC<DialogPromptSelectNextDialogComponentProps> =
    ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, addDialog }) => {

        const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

        const [response, setResponse] = React.useState<string | null>(null);

        const [reminderMessage, setReminderMessage] = React.useState<string | null>(null);
        const [reminderMessageDone, setReminderMessageDone] = React.useState(false);
        const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
        const [chatBubbleResponseDone, setChatBubbleResponseDone] = React.useState(false);

        React.useEffect(() => {
            // clear promptInputComponent initially
            setPromptInputComponent(null);

            // what reminder message do we need? (if any)
            UserApi.get()
                .then(response => {
                    // Handle the response
                    const user: IUser = response.data;
                    if (user.email === undefined || user.email === null || user.read_only_email_validated == false) {
                        setReminderMessage("Prior to proceeding, kindly consider creating an account if you find value in this lesson. This will enable you to receive notifications about upcoming lessons.");
                    } else {
                        setReminderMessageDone(true);
                    }
                })
                .catch(error => {
                    // Handle the error
                    console.error(error);
                    setErrorMessage('Error getting user:' + error.toString());
                    setReminderMessageDone(true);
                });
        }, []);

        const handleButtonClick = (nextDialog: INextDialog) => {
            setResponse(nextDialog.response_text);
            addDialog(nextDialog.next_dialog_id);
        }

        React.useEffect(() => {
            if (chatBubbleDone) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center' }}>
                        {dialogPrompt.next_dialog_list.map((nextDialog) => (
                            <Button variant="contained" color="primary" onClick={() => handleButtonClick(nextDialog)} sx={{ ml: 4 }}>
                                {nextDialog.button_text}
                            </Button>
                        ))}
                    </Stack>
                )
            }
        }, [chatBubbleDone]);

        React.useEffect(() => {
            if (chatBubbleResponseDone) {
                setDialogPromptComplete(dialogPrompt.id);
            }
        }, [chatBubbleResponseDone])

        return (
            <Stack>
                {reminderMessage && (
                    <ChatBubble
                        isTeacher={true}
                        message={reminderMessage}
                        showAvatar={showAvatar}
                        setChatBubbleDone={setReminderMessageDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                )}
                {reminderMessageDone && (
                    <ChatBubble
                        isTeacher={dialogPrompt.is_teacher}
                        message={dialogPrompt.prompt}
                        showAvatar={showAvatar}
                        setChatBubbleDone={setChatBubbleDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                )}
                {response ? (
                    <ChatBubble
                        isTeacher={!dialogPrompt.is_teacher}
                        message={response}
                        showAvatar={showAvatar}
                        setChatBubbleDone={setChatBubbleResponseDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                ) : null}
            </Stack>
        );
    };

export default DialogPromptSelectNextDialogComponent;
