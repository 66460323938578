import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptSpeakNoGrade } from "../../api/DialogPromptApi";
import ErrorMessageContext from "../../context/ErrorMessageContext";

interface DialogPromptSpeakNoGradeFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string | null;
    imageSource: string | null;
}

export const DialogPromptSpeakNoGradeForm: React.FC<DialogPromptSpeakNoGradeFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, imageSource }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState("");
    const [studentResponsePrompt, setStudentResponsePrompt] = React.useState("<Answer/>");
    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    const handleStudentResponsePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponsePrompt(e.target.value); };

    React.useEffect(() => {
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
            DialogPromptApi.getById(oldDialogPromptId)
                .then((response) => {
                    const oldDialogPrompt = response.data as DialogPromptSpeakNoGrade;
                    setDialogPromptFields(oldDialogPrompt);
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.message);
                    }
                })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptSpeakNoGrade)
        // }
    }, [oldDialogPromptId])

    function setDialogPromptFields(dialogPrompt: DialogPromptSpeakNoGrade) {
        if (dialogPrompt.class_name == "DialogPromptSpeakNoGrade") {
            setTeacherChinesePrompt(dialogPrompt.teacher_chinese_prompt ? dialogPrompt.teacher_chinese_prompt : "")
            setStudentResponsePrompt(dialogPrompt.student_response_prompt ? dialogPrompt.student_response_prompt : "")
        }
    }

    React.useEffect(() => {

        const dialogPrompt = new DialogPromptSpeakNoGrade(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse as string | "ERROR",
            imageSource,
            teacherChinesePrompt,
            null, // sentence_id is null
            studentResponsePrompt,
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, imageSource,
        teacherChinesePrompt, studentResponsePrompt])

    return (
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>

            <TextField
                label="Teacher Chinese Prompt"
                value={teacherChinesePrompt}
                onChange={handleTeacherChinesePromptChange}
            />
            <div>
                <TextField
                    label="Student Chinese Prompt"
                    value={studentResponsePrompt ? studentResponsePrompt : "<Answer/>"}
                    onChange={handleStudentResponsePromptChange}
                    required
                    sx={{ width: '100%' }}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted
                </Typography>
            </div>
        </Stack>
    )
}