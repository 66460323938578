import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, InputLabel, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptMatchWordPicture } from "../../api/DialogPromptApi";
import { IVocabularyWord } from "../../api/VocabularyWordApi";
import ErrorMessageContext from "../../context/ErrorMessageContext";

interface DialogPromptMatchWordPictureFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string;
    noResponse: string;
}

export const base64ToArrayBuffer = (base64String: string) => {
    const binaryString = window.atob(base64String);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
};

export function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}


export const DialogPromptMatchWordPictureForm: React.FC<DialogPromptMatchWordPictureFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [imageError, setImageError] = React.useState<boolean[]>([]);
    const [vocabularyWordList, setVocabularyWordList] = React.useState<IVocabularyWord[]>([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
    const [wordIndexToDelete, setWordIndexToDelete] = React.useState<number | null>(null);

    React.useEffect(() => {
        // // is oldDialogPromptId in the update queue (currently being saved)?
        // // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
            DialogPromptApi.getById(oldDialogPromptId)
                .then((response) => {
                    const oldDialogPrompt = response.data as DialogPromptMatchWordPicture;
                    setDialogPromptFields(oldDialogPrompt);
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.message);
                    }
                })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptMatchWordPicture)
        //     // setIsSaving(true)
        // }
    }, [oldDialogPromptId])


    function setDialogPromptFields(dialogPrompt: DialogPromptMatchWordPicture) {
        if (dialogPrompt.class_name == "DialogPromptMatchWordPicture") {
            setVocabularyWordList(dialogPrompt.vocabulary_word_list);
            // Create a new array with the same length as vocabulary_word_list
            const imageErrorArray = dialogPrompt.vocabulary_word_list.map((vocabularyWordOption) => {
                return (vocabularyWordOption.image == null) || (vocabularyWordOption.image == '');
            });

            // Set the imageError state with the new array
            setImageError(imageErrorArray);
        }

    }

    React.useEffect(() => {
        const dialogPrompt = new DialogPromptMatchWordPicture(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse,
            noResponse,
            vocabularyWordList
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, vocabularyWordList])

    const handleAddVocabularyWord = () => {
        const newVocabularyWord: IVocabularyWord = {
            id: 0,
            word: '',
            image: '',
            audio_sentence: '',
            audio_sentence_id: 0,
            dialog_prompt_id: 0,
            teacher_english_prompt: '',
            teacher_chinese_prompt: '',
            teacher_chinese_prompt_sentence_id: 0,
            student_response_prompt: '<Answer/>',
            student_response_prompt_sentence_id: 0
        };
        if (vocabularyWordList) {
            setVocabularyWordList([...vocabularyWordList, newVocabularyWord]);
            setImageError((prevImageError) => [...prevImageError, true])
        } else {
            setVocabularyWordList([newVocabularyWord]);
            setImageError([true]);
        }
    };

    const handleVocabularyWordChange = (
        index: number,
        field: string,
        value: string
    ) => {
        setVocabularyWordList((prevWords) =>
            prevWords.map((word, i) => {
                if (i === index) {
                    return { ...word, [field]: value };
                }
                return word;
            })
        );
    };
    const handleImageUpload = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        // Clear the image error for the specific image
        setImageError((prevImageError) => {
            const updatedError = [...prevImageError];
            updatedError[index] = false;
            return updatedError;
        });
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Code to handle the reader result
                let base64String: string;
                let result = reader.result;
                if (typeof result === 'string') {
                    base64String = result;
                } else if (result instanceof ArrayBuffer) {
                    base64String = arrayBufferToBase64(result)
                } else {
                    base64String = ""
                }
                setVocabularyWordList((prevWords) =>
                    prevWords.map((word: IVocabularyWord, i) => {
                        if (i == index) {
                            return { ...word, image: base64String }
                        }
                        return word;
                    })
                )
            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteVocabularyWord = (index: number) => {
        setWordIndexToDelete(index);
        setDeleteConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {
        if (wordIndexToDelete !== null) {
            setVocabularyWordList(prevList => prevList.filter((word, index) => index !== wordIndexToDelete));
        }
        setWordIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    const handleCancelDelete = () => {
        setWordIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };
    return (<>
        {vocabularyWordList && vocabularyWordList.map((word, index) => {
            // Assuming word.image contains the ArrayBuffer
            return (

                <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }} key={index}>
                    <h3>Vocabulary Word</h3>
                    <TextField
                        label="Word"
                        value={word.word}
                        onChange={(e) =>
                            handleVocabularyWordChange(index, 'word', e.target.value)
                        }
                        required
                    />
                    <Box>
                        <InputLabel htmlFor="image-upload">Prompt with Image</InputLabel>
                        <Input
                            type="file"
                            id="image-upload"
                            inputProps={{ accept: "image/*" }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(index, e)}
                        />
                    </Box>
                    <img
                        src={word.image}
                        alt="Uploaded"
                        style={{
                            maxHeight: '200px', maxWidth: '200px',
                            height: 'auto', width: 'auto',
                            margin: '0 auto', // Add this line to center the image horizontally
                            display: 'block', // Add this line to ensure the margin works correctly
                        }}
                    />
                    {imageError[index] && (
                        <Typography sx={{ color: 'error.main', fontSize: '0.9rem' }}>
                            Image is required.
                        </Typography>
                    )}
                    <TextField
                        label="Teacher English Prompt"
                        value={word.teacher_english_prompt}
                        onChange={(e) =>
                            handleVocabularyWordChange(
                                index,
                                'teacher_english_prompt',
                                e.target.value
                            )
                        }
                    />
                    <TextField
                        label="Teacher Chinese Prompt"
                        value={word.teacher_chinese_prompt}
                        onChange={(e) =>
                            handleVocabularyWordChange(
                                index,
                                'teacher_chinese_prompt',
                                e.target.value
                            )
                        }
                    />
                    <div>
                        <TextField
                            label="Student Chinese Response Prompt"
                            value={word.student_response_prompt ? word.student_response_prompt : "<Answer/>"}
                            onChange={(e) =>
                                handleVocabularyWordChange(
                                    index,
                                    'student_response_prompt',
                                    e.target.value
                                )
                            }
                            required
                            sx={{ width: '100%' }}
                        />
                        <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                            Use &lt;Answer/&gt; to mark where the response is inserted
                        </Typography>
                    </div>
                    <Button variant="outlined" onClick={() => handleDeleteVocabularyWord(index)}>
                        Delete
                    </Button>
                </Stack>
            )
        })}
        <Button variant="outlined" onClick={handleAddVocabularyWord}>
            Add Vocabulary Word
        </Button>
        <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this vocabulary word {wordIndexToDelete !== null && (<>{vocabularyWordList[wordIndexToDelete].word}</>)} ?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDelete} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>);
};
