import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CourseApi, ICourse } from '../../api/CourseApi';
import { DialogApi, IDialog } from '../../api/DialogApi';
import { DialogPrompt, DialogPromptApi, DialogPromptFactory, DialogPromptImage, DialogPromptIntroduceWord, DialogPromptLearnWithCharacter, DialogPromptListenSpeak, DialogPromptListenSpeakNoGrade, DialogPromptMatchWordPicture } from '../../api/DialogPromptApi';
import { ILesson, LessonApi } from '../../api/LessonApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import { DialogPromptConversationListenForm } from './DialogPromptConversationListenForm';
import { DialogPromptConversationRelatedForm } from './DialogPromptConversationRelatedForm';
import { DialogPromptMatchWordPictureForm, arrayBufferToBase64 } from './DialogPromptMatchWordPictureForm';
import { DialogPromptQuizQuestionAudioForm } from './DialogPromptQuizQuestionAudioForm';
import { DialogPromptQuizQuestionForm } from './DialogPromptQuizQuestionForm';
import { DialogPromptQuizWordForm } from './DialogPromptQuizWordForm';
import { DialogPromptSelectNextDialogForm } from './DialogPromptSelectNextDialogForm';
import { DialogPromptSpeakNoGradeForm } from './DialogPromptSpeakNoGradeForm';
import { DialogPromptSpeakWithGradeForm } from './DialogPromptSpeakWithGradeForm';
import { DialogPromptTypeSpeakForm } from './DialogPromptTypeSpeakForm';

interface DialogPromptFormProps {
    dialogPromptId: number;
    onSubmit: (updatedDialogPrompt: DialogPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete: (dialogPromptId: number) => void;
}

export function dialogPromptClassNameContainsProp(className: string, prop: string) {
    if (className == null || className.length <= 0) return false;
    // don't need to include all fields, just getting DialogPrompt object
    const selectedDialogPrompt = DialogPromptFactory(
        className,
        -1,
        -1,
        -1,
        true,
        "ERROR-dialogPromptClassNameContainsProp",
        true);
    return prop in selectedDialogPrompt
}

const DialogPromptForm: React.FC<DialogPromptFormProps> = ({ dialogPromptId: oldDialogPromptId, onSubmit, onDelete }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    // Initial values reset by useEffect below
    const [id, setId] = React.useState(-1);
    const [dialogId, setDialogId] = React.useState(-1);
    const [courseDictionary, setCourseDictionary] = React.useState<{ [key: number]: ICourse; }>({}); // State to store course options
    const [lessonDictionary, setLessonDictionary] = React.useState<{ [key: number]: ILesson; }>({});
    const [dialogList, setDialogList] = React.useState<IDialog[]>([]);

    const [className, setClassName] = React.useState('');
    const [isTeacher, setIsTeacher] = React.useState(true);
    const [prompt, setPrompt] = React.useState('');
    const [isNoteToStudent, setIsNoteToStudent] = React.useState(Boolean);
    const [orderIndex, setOrderIndex] = React.useState(-1);

    const [imageSource, setImageSource] = React.useState("");
    const [imageError, setImageError] = React.useState(false);

    const [newWord, setNewWord] = React.useState("");
    const [yesButtonText, setYesButtonText] = React.useState("");
    const [noButtonText, setNoButtonText] = React.useState("");
    const [yesResponse, setYesResponse] = React.useState("");
    const [noResponse, setNoResponse] = React.useState("");

    const [newDialogPrompt, setNewDialogPrompt] = React.useState<DialogPrompt | null>(null);

    const [sentenceId, setSentenceId] = React.useState(-1)

    const [isSaving, setIsSaving] = React.useState(false); // Add saving state

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    React.useEffect(() => {
        const fetchCourseList = async () => {
            try {
                const response = await CourseApi.getAll();
                const courseList = response.data

                const courseDictionary = courseList.reduce((accumulator, currentCourse) => {
                    accumulator[currentCourse.id] = currentCourse;
                    return accumulator;
                  }, {} as { [key: number]: typeof courseList[number] }); // Using "typeof courseList[number]" to infer course type
                setCourseDictionary(courseDictionary)
                  
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            }
        };
        const fetchLessonList = async () => {
            try {
                const response = await LessonApi.getAll();
                const lessonList = response.data;

                const lessonDictionary = lessonList.reduce((accumulator, currentCourse) => {
                    accumulator[currentCourse.id] = currentCourse;
                    return accumulator;
                  }, {} as { [key: number]: typeof lessonList[number] }); // Using "typeof courseList[number]" to infer course type
                setLessonDictionary(lessonDictionary)
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            }
        };
        const fetchDialogList = async () => {
            try {
                const response = await DialogApi.getAll();
                setDialogList(response.data);
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            }
        };
        fetchCourseList();
        fetchLessonList();
        fetchDialogList();
    }, []);

    React.useEffect(() => {
        setImageError(false)
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
            DialogPromptApi.getById(oldDialogPromptId)
                .then((response) => {
                    const oldDialogPrompt = response.data;
                    setDialogPromptFields(oldDialogPrompt);
                    // const dialogPromptInQueue = dialogPromptUpdateQueue.some((dialogPrompt) => dialogPrompt.dialogPrompt.id === oldDialogPromptId);
                    // setIsSaving(dialogPromptInQueue)
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.message);
                    }
                })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt)
        //     setIsSaving(true)
        // }
    }, [oldDialogPromptId])

    function setDialogPromptFields(dialogPrompt: DialogPrompt) {
        setId(dialogPrompt.id);
        setDialogId(dialogPrompt.dialog_id);
        setClassName(dialogPrompt.class_name);
        setIsTeacher(dialogPrompt.is_teacher);
        setPrompt(dialogPrompt.prompt);
        setIsNoteToStudent(dialogPrompt.is_note_to_student ? dialogPrompt.is_note_to_student : false);
        setOrderIndex(dialogPrompt.order_index);

        if ("image_src" in dialogPrompt) {
            setImageSource((dialogPrompt as DialogPromptImage).image_src);
        }
        if ("new_word" in dialogPrompt) {
            setNewWord((dialogPrompt as DialogPromptIntroduceWord).new_word);
        }
        if ("yes_button_text" in dialogPrompt) {
            setYesButtonText((dialogPrompt as DialogPromptLearnWithCharacter).yes_button_text)
            setNoButtonText((dialogPrompt as DialogPromptLearnWithCharacter).no_button_text)
        }
        if ("no_response" in dialogPrompt) {
            setNoResponse((dialogPrompt as DialogPromptListenSpeak).no_response)
        }
        if ("yes_response" in dialogPrompt) {
            setYesResponse((dialogPrompt as DialogPromptListenSpeakNoGrade).yes_response)
        }
        if ("sentence_id" in dialogPrompt) {
            setSentenceId((dialogPrompt as DialogPromptIntroduceWord).sentence_id)
        }
    }

    // React.useEffect(() => {
    //     // update isSaving after anything changes with queue
    //     const foundIndex = dialogPromptUpdateQueue.findIndex((tuple) => tuple.dialogPrompt.id === oldDialogPromptId);
    //     setIsSaving(foundIndex >= 0)

    // }, [dialogPromptUpdateQueue]);

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => { setId(Number(e.target.value)); };
    const handleDialogIdChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDialogId(Number(e.target.value)); };
    const handleClassNameChange = (e: SelectChangeEvent<string>) => { setClassName(e.target.value); };
    const handleIsTeacherChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsTeacher(e.target.value === 'true'); };
    const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setPrompt(e.target.value); };
    const handleIsNoteToStudentChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsNoteToStudent(e.target.value === 'on'); };
    const handleOrderIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => { setOrderIndex(Number(e.target.value)); };
    const handleNewWordChange = (e: React.ChangeEvent<HTMLInputElement>) => { setNewWord(e.target.value); };

    const handleYesButtonTextChange = (e: React.ChangeEvent<HTMLInputElement>) => { setYesButtonText(e.target.value); };
    const handleNoButtonTextChange = (e: React.ChangeEvent<HTMLInputElement>) => { setNoButtonText(e.target.value); };
    const handleYesResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setYesResponse(e.target.value); };
    const handleNoResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setNoResponse(e.target.value); };

    const handleSentenceIdChange = (e: React.ChangeEvent<HTMLInputElement>) => { setSentenceId(Number(e.target.value)); };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSaving(true); // Set saving state to true

        if (className == "DialogPromptImage" && dialogPromptClassNameContainsProp(className, "image_src") &&
            (imageSource.trim() === "" || imageSource.trim() === "broken")) {
            // If the image is empty, set the image error state to true
            setImageError(true);
            setIsSaving(false); // Set saving state to false
            return; // Don't proceed with form submission
        }

        if (className == "DialogPromptMatchWordPicture") {
            if (newDialogPrompt != null) {
                const vocabList = (newDialogPrompt as DialogPromptMatchWordPicture).vocabulary_word_list;
                const vocabMissingImage = vocabList.some((vocab) => vocab.image == null || vocab.image == '');
                if (vocabMissingImage) {
                    setIsSaving(false);
                    return;
                }
            }
        }
        if (className == "DialogPromptQuizWord" ||
            className == "DialogPromptMatchWordPicture" ||
            className == "DialogPromptTypeSpeak" ||
            className == "DialogPromptQuizQuestion" ||
            className == "DialogPromptQuizQuestionAudio" ||
            className == "DialogPromptConversationListen" ||
            className == "DialogPromptConversationListenSpeak" ||
            className == "DialogPromptConversationListenWithoutCharacter" ||
            className == "DialogPromptConversationReadSpeak" ||
            className == "DialogPromptConversationReadWithBlankSpeak" ||
            className == "DialogPromptConversationSpeakOnly" ||
            className == "DialogPromptSelectNextDialog" ||
            className == "DialogPromptSpeakNoGrade" ||
            className == "DialogPromptSpeakWithGrade") {
            if (newDialogPrompt != null) {
                onSubmit(newDialogPrompt, setIsSaving);
            }
        } else {
            const thisDialogPrompt = DialogPromptFactory(
                className,
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                imageSource as string | null,
                newWord as string | null,
                yesButtonText as string | null,
                noButtonText as string | null,
                yesResponse as string | null,
                noResponse as string | null,
                sentenceId as number | null)
            onSubmit(thisDialogPrompt, setIsSaving);
        }
    }

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Clear the image error
        setImageError(false);

        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Code to handle the reader result
                let base64String: string;
                let result = reader.result;
                if (typeof result === 'string') {
                    base64String = result;
                } else if (result instanceof ArrayBuffer) {
                    base64String = arrayBufferToBase64(result)
                } else {
                    base64String = ""
                }
                setImageSource(base64String);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteImage = () => {
        // Set the wordImage state to an empty string to remove the image
        setImageSource('');
    };

    const handleDeleteConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setShowConfirmation(false);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmation(false);
        onDelete(oldDialogPromptId);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
                <Typography variant="h5">
                    Edit DialogPrompt
                </Typography>
                <input type="hidden" value={id} onChange={handleIdChange} />
                <input type="hidden" value={dialogId} onChange={handleDialogIdChange} />
                <input type="hidden" value={sentenceId as number} onChange={handleSentenceIdChange} />
                <FormControl>
                    <InputLabel id="class-name-label">Class Name</InputLabel>
                    <Select
                        labelId="class-name-label"
                        id="class-name-select"
                        value={className}
                        onChange={handleClassNameChange}
                        required
                    >

                        <MenuItem value="DialogPrompt">Dialog Only</MenuItem>
                        <MenuItem value="DialogPromptImage">Dialog w/ Image</MenuItem>
                        <MenuItem value="DialogPromptIntroduceWord">Dialog w/ Character Listen</MenuItem>
                        <MenuItem value="DialogPromptListenSpeak">Dialog w/ Listen & Speak</MenuItem>
                        <MenuItem value="DialogPromptListenSpeakNoGrade">Dialog w/ Listen & Speak Anything, No Grade</MenuItem>
                        <MenuItem value="DialogPromptQuizWord">Quiz: Character Recognition</MenuItem>
                        <MenuItem value="DialogPromptMatchWordPicture">Quiz: Matching on Vocabulary</MenuItem>
                        <MenuItem value="DialogPromptQuizQuestion">Quiz: Select One</MenuItem>
                        <MenuItem value="DialogPromptQuizQuestionAudio">Quiz: Select One Audio</MenuItem>
                        <MenuItem value="DialogPromptTypeSpeak">Prompt, Type, Speak</MenuItem>
                        <MenuItem value="DialogPromptSpeakNoGrade">Prompt, Speak Anything, No Grade</MenuItem>
                        <MenuItem value="DialogPromptSpeakWithGrade">Prompt, Speak With Grade</MenuItem>
                        <MenuItem value="DialogPromptConversationListen">Conversation: Listen</MenuItem>
                        <MenuItem value="DialogPromptConversationListenWithoutCharacter">Conversation: Listen, without Character</MenuItem>
                        <MenuItem value="DialogPromptConversationListenSpeak">Conversation: Listen then Speak</MenuItem>
                        <MenuItem value="DialogPromptConversationReadSpeak">Conversation: Read then Speak</MenuItem>
                        <MenuItem value="DialogPromptConversationReadWithBlankSpeak">Conversation: Read w/ blank then Speak</MenuItem>
                        <MenuItem value="DialogPromptConversationSpeakOnly">Conversation: Speak Only</MenuItem>

                        <MenuItem value="DialogPromptSelectNextDialog">Select next dialog</MenuItem>
                        <MenuItem value="DialogPromptStartNextLesson">Start next lesson</MenuItem>

                        <MenuItem value="DialogPromptSelectLengthTime">Enter length of time</MenuItem>
                        <MenuItem value="DialogPromptEnterName">Enter Name</MenuItem>
                        <MenuItem value="DialogPromptSelectAvatar">Select Avatar</MenuItem>
                        <MenuItem value="DialogPromptSelectTeacher">Select Teacher</MenuItem>
                        <MenuItem value="DialogPromptLearnWithCharacter">Learn Characters</MenuItem>
                        <MenuItem value="DialogPromptStartClass">Start First Lesson</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>Parent Dialog Name</InputLabel>
                    <Select
                        value={dialogId}
                        label="Parent Dialog Name"
                        onChange={e => setDialogId(Number(e.target.value))}
                        required
                    >
                        <MenuItem key={-1} value={-1}/>
                        {dialogList.map((dialog) => (
                            <MenuItem key={dialog.id} value={dialog.id}>
                                {dialog.title}  
                                :: {lessonDictionary[dialog.lesson_id] ? lessonDictionary[dialog.lesson_id].name : "Lesson Deleted"} ::
                                {lessonDictionary[dialog.lesson_id] ? (courseDictionary[lessonDictionary[dialog.lesson_id].course_id] ? courseDictionary[lessonDictionary[dialog.lesson_id].course_id].name : "Course Deleted" ) : "Course Deleted"}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="isTeacher"
                        name="isTeacher"
                        value={isTeacher.toString()}
                        onChange={handleIsTeacherChange}
                    >
                        <FormControlLabel value="true" control={<Radio required />} label="Teacher" />
                        <FormControlLabel value="false" control={<Radio required />} label="Student" />
                    </RadioGroup>
                </FormControl>
                <div>
                    <TextField
                        label="Prompt"
                        value={prompt}
                        onChange={handlePromptChange}
                        multiline
                        rows={3}
                        sx={{ width: '100%' }}
                        inputProps={{
                            style: {
                                resize: 'vertical',
                                overflow: 'hidden',
                            },
                        }}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isNoteToStudent} onChange={handleIsNoteToStudentChange} />}
                        label="Is a Note to the Student"
                    />
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;StudentName/&gt; will be replaced with the student's name
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;TeacherName/&gt; will be replaced with the teacher's name
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Microphone/&gt; will be replaced with the microphone icon for recording
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Speaker/&gt; will be replaced with the speaker icon for listening
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Info/&gt; will be replaced with the info icon for more details
                    </Typography>
                </div>

                {dialogPromptClassNameContainsProp(className, "image_src") && (<>
                    <Box>
                        <InputLabel htmlFor="image-upload">Prompt with Image</InputLabel>
                        <Input
                            type="file"
                            id="image-upload"
                            inputProps={{ accept: "image/*" }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(e)}
                        />
                    </Box>
                    {imageSource && (
                        <img
                            src={imageSource}
                            alt="Uploaded"
                            style={{
                                maxHeight: '200px', maxWidth: '200px',
                                height: 'auto', width: 'auto',
                                margin: '0 auto', // Add this line to center the image horizontally
                                display: 'block', // Add this line to ensure the margin works correctly
                            }}
                        />
                    )}
                    {/* Add the delete image button */}
                    {(imageSource && imageSource != "broken") && (
                        <Box display="inline-block">
                            <Button variant="outlined" onClick={handleDeleteImage}>
                                Delete Image
                            </Button>
                        </Box>
                    )}
                    {/* Display the image error message */}
                    {imageError && (
                        <Typography sx={{ color: 'error.main', fontSize: '0.9rem' }}>
                            Image is required.
                        </Typography>
                    )}
                </>
                )}

                {dialogPromptClassNameContainsProp(className, "new_word") && (
                    <TextField
                        label="New Word"
                        value={newWord}
                        onChange={handleNewWordChange}
                        required
                    />
                )}

                {dialogPromptClassNameContainsProp(className, "yes_button_text") && (<>
                    <TextField
                        label="Yes Button Text"
                        value={yesButtonText}
                        onChange={handleYesButtonTextChange}
                        required
                    />
                    <TextField
                        label="No Button Text"
                        value={noButtonText}
                        onChange={handleNoButtonTextChange}
                        required
                    />
                </>)}

                {dialogPromptClassNameContainsProp(className, "yes_response") && (<>
                    <TextField
                        label="Yes Response"
                        value={yesResponse}
                        onChange={handleYesResponseChange}
                        required
                    />
                </>)}
                {dialogPromptClassNameContainsProp(className, "no_response") && (<>
                    <TextField
                        label="No Response"
                        value={noResponse}
                        onChange={handleNoResponseChange}
                        required
                    />
                </>)}
                {className == "DialogPromptQuizWord" &&
                    <DialogPromptQuizWordForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent}
                        newWord={newWord} yesResponse={yesResponse} noResponse={noResponse} />}
                {className == "DialogPromptTypeSpeak" &&
                    <DialogPromptTypeSpeakForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} imageSource={imageSource} />}
                {className == "DialogPromptMatchWordPicture" &&
                    <DialogPromptMatchWordPictureForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} />}
                {className == "DialogPromptQuizQuestion" &&
                    <DialogPromptQuizQuestionForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} imageSource={imageSource} />}
                {className == "DialogPromptQuizQuestionAudio" &&
                    <DialogPromptQuizQuestionAudioForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} imageSource={imageSource} />}
                {className == "DialogPromptConversationListen" &&
                    <DialogPromptConversationListenForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} />}
                {(className == "DialogPromptConversationListenSpeak" ||
                    className == "DialogPromptConversationListenWithoutCharacter" ||
                    className == "DialogPromptConversationReadSpeak" ||
                    className == "DialogPromptConversationReadWithBlankSpeak" ||
                    className == "DialogPromptConversationSpeakOnly")
                    &&
                    <DialogPromptConversationRelatedForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse}
                        dialogPromptClassName={className} />}
                {className == "DialogPromptSelectNextDialog" &&
                    <DialogPromptSelectNextDialogForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} />}

                {className == "DialogPromptSpeakNoGrade" &&
                    <DialogPromptSpeakNoGradeForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} imageSource={imageSource} />}
                {className == "DialogPromptSpeakWithGrade" &&
                    <DialogPromptSpeakWithGradeForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} imageSource={imageSource} />}


                <TextField
                    label="Sequence Number"
                    value={orderIndex}
                    onChange={handleOrderIndexChange}
                    type="number"
                    required
                />
                <br />
                <Button disabled={isSaving} type="submit" variant="contained">
                    {isSaving ? <CircularProgress size={24} /> : 'Save'}
                </Button>
                <Button variant="contained" color="error" onClick={handleDeleteConfirmation}>
                    Delete
                </Button>
            </Stack>
            <Dialog open={showConfirmation} onClose={handleDeleteCancel}>
                <DialogTitle>Delete DialogPrompt</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this dialogPrompt?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default DialogPromptForm;