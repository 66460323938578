import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DialogPromptApi, DialogPromptConversationListen, DialogPromptConversationListenWithoutCharacter, DialogPromptConversationReadWithBlankSpeak, DialogPromptConversationRelated, IDialogPromptSentence } from '../../api/DialogPromptApi';
import AddSelfStudyBlueIcon from '../../asset/icon-add-self-study-blue.png';
import MicrophoneBlueIcon from '../../asset/icon-microphone-blue.png';
import RightArrowBlueIcon from '../../asset/icon-right-arrow-blue.png';
import SpeakerBlueIcon from '../../asset/icon-speaker-blue.svg';
import AudioWaveIcon from '../../asset/icons8-audio-wave.gif';
import { useKaiTiFontStyles } from '../../font/KaiTiFont';
import ChatBubble from '../ChatBubble';


import { useHistory } from 'react-router-dom';
import { sentenceScoreAllGood, sentenceScoreAnyFailed } from '../../Score';
import { AudioApi } from '../../api/AudioApi';
import { CustomSentenceApi } from '../../api/CustomSentenceApi';
import { ISentenceScore, ScoreApi } from '../../api/ScoreApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import { dialogPromptClassNameContainsProp } from './DialogPromptForm';

interface DialogPromptConversationRelatedComponentProps {
    dialogPrompt: DialogPromptConversationRelated | DialogPromptConversationListenWithoutCharacter;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptConversationRelatedComponent: React.FC<DialogPromptConversationRelatedComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    // console.log('Original Type of dialogPrompt:', dialogPrompt.constructor.name);

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [dialogSentenceDone, setDialogSentenceDone] = React.useState(false);

    const kaiTiFontClass = useKaiTiFontStyles();

    const [dialogPromptSentenceList, setDialogPromptSentenceList] = React.useState<IDialogPromptSentence[]>([]);
    const initialDialogPromptIndex = dialogPrompt.class_name == 'DialogPromptConversationSpeakOnly' ? 1 : 0;
    const [dialogPromptIndex, setDialogPromptIndex] = React.useState(initialDialogPromptIndex);
    const dialogPromptIndexRef = React.useRef(initialDialogPromptIndex); // Initialize the ref outside the component function

    const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
    const [isRecording, setIsRecording] = React.useState(false);
    const [isScoring, setIsScoring] = React.useState(false);

    const [chatObjectList, setChatObjectList] = React.useState<any[]>([]);

    const [sentenceScoreBubbleDone, setSentenceScoreBubbleDone] = React.useState(false);


    const [chatBubbleTeacherResponseDone, setChatBubbleTeacherResponseDone] = React.useState<boolean[]>([]);
    const [tryAgainClickedList, setTryAgainClickedList] = React.useState<(boolean | null)[]>([]);
    const [chatBubbleTryAgainDone, setChatBubbleTryAgainDone] = React.useState(false);
    const [chatBubbleContinueDone, setChatBubbleContinueDone] = React.useState(false);

    let audioData: Blob | null = null;

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        DialogPromptApi.getById(dialogPrompt.dialog_prompt_conversation_listen_id)
            .then((response) => {
                const oldDialogPrompt = response.data as DialogPromptConversationListen;
                setDialogPromptSentenceList(oldDialogPrompt.dialog_prompt_sentence_list);
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }, []);


    const [addSentenceDialogOpen, setAddSentenceDialogOpen] = React.useState(false);
    const [addSentenceSuccessMessage, setAddSentenceSuccessMessage] = React.useState('');

    const handleAddSentenceClick = () => {
        setAddSentenceDialogOpen(true);
    };

    const handleConfirm = () => {
        const sentenceId = dialogPromptSentenceList[dialogPromptIndexRef.current].sentence_id
        CustomSentenceApi.createBySentenceId(sentenceId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error('Unable to create sentence statuscode=' + response.status + " " + response.statusText);
            })
            .then(list => {
                // Update success message
                setAddSentenceSuccessMessage('Sentence added successfully!');
                // Don't Close the dialog, allow user to see success message
                // setAddSentenceDialogOpen(false);
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else if (err.response.status == 490) {
                    setAddSentenceSuccessMessage("Please validate your email before adding sentences. You can validate your email in your profile.");
                } else if (err.response.status == 491) {
                    setAddSentenceSuccessMessage(err.response['data']);
                } else if (err.response.status == 492) {
                    setAddSentenceSuccessMessage("This user is read only.");
                } else {
                    console.error(err);
                    setAddSentenceSuccessMessage(err.message);
                }
            })
    };

    const handleClose = () => {
        setAddSentenceDialogOpen(false);
        setAddSentenceSuccessMessage('')
    };

    React.useEffect(() => {
        if (chatBubbleDone || isRecording || isScoring || tryAgainClickedList[tryAgainClickedList.length - 1]) {
            if (dialogSentenceDone == false) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        {/* Left-aligned X icon */}
                        <IconButton onClick={handleCancelClick}>
                            <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                        </IconButton>
                        <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center', flexGrow: 1 }} spacing={2}>
                            {dialogPrompt.class_name != "DialogPromptConversationListenWithoutCharacter" &&
                                <IconButton onClick={isRecording ? stopRecording : startRecording}>
                                    {isRecording ? <img src={AudioWaveIcon} alt="Recording" style={{ width: '32px', height: '32px' }} />
                                        : isScoring ? <CircularProgress size={24} sx={{ width: '24px', height: '24px' }} />
                                            : <img src={MicrophoneBlueIcon} style={{ width: '36px', height: '36px' }} />}
                                </IconButton>
                            }
                        </Stack>
                        <Tooltip title="Add to Self-Study" arrow>
                            <IconButton onClick={handleAddSentenceClick} sx={{ alignSelf: 'center' }}>
                                <img src={AddSelfStudyBlueIcon} style={{ width: '36px', height: '36px' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            }
        }
        if (chatBubbleDone) {
            setChatBubbleDone(false);
            // check dialogPromptIndexRef.current, and add all missing dialog prompts
            const additionalDialogPrompt: IDialogPromptSentence[] = [];

            for (let i = 0; i <= dialogPromptIndexRef.current; i++) {
                const dialogPromptSentence = dialogPromptSentenceList[i];

                // Check if the dialogPromptSentence's id is not in chatObjectList
                const isNotInChatObjectList = !chatObjectList.some(
                    (chatObject) => 'dialog_prompt_id' in chatObject && 'sentence_id' in chatObject && chatObject.id === dialogPromptSentence.id);

                if (isNotInChatObjectList) {
                    additionalDialogPrompt.push(dialogPromptSentence);
                }
            }
            setChatObjectList((previous) => [...previous, ...additionalDialogPrompt]);

        }
    }, [chatBubbleDone, isRecording, isScoring, tryAgainClickedList])

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatObjectList]);

    const handleSpeakerClick = (dialogPromptIndex: (number | null) = null) => {
        let audioPromise;
        if (dialogPromptIndex == null) {
            dialogPromptIndex = dialogPromptIndexRef.current
        }

        if (dialogPromptIndex % 2 === 0) {
            audioPromise = AudioApi.getSpeakerA(dialogPromptSentenceList[dialogPromptIndex].sentence_id)
        } else {
            audioPromise = AudioApi.getSpeakerB(dialogPromptSentenceList[dialogPromptIndex].sentence_id)
        }


        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                if (dialogPrompt.class_name == "DialogPromptConversationListenWithoutCharacter") {
                    audio.addEventListener('ended', () => {
                        setChatBubbleContinueDone(true);
                    }); // Listen to the 'ended' event
                }
                audio.play();
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    };

    const startRecording = () => {
        // console.log("startRecording() start")
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                // console.log("startRecording() 1")
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.addEventListener('dataavailable', handleAudioDataAvailable);
                mediaRecorderRef.current.addEventListener('stop', handleAudioDone);

                mediaRecorderRef.current.start();

                setIsRecording(true);
                // console.log("startRecording() 2")
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Error accessing microphone:' + error.toString())
            });
    };
    const stopRecording = () => {
        // console.log("stopRecording() start")
        if (mediaRecorderRef.current) {
            // console.log("stopRecording() 1")
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.removeEventListener('dataavailable', handleAudioDataAvailable);
            mediaRecorderRef.current.removeEventListener('stop', handleAudioDone);
            // console.log("stopRecording() 2")
        }
        setIsRecording(false);
    };

    const handleAudioDataAvailable = (event: BlobEvent) => {
        // console.log("handleAudioDataAvailable() 1")
        if (event.data.size > 0) {
            // console.log("event.data.size > 0")
            audioData = event.data;
        }
    };

    const handleAudioDone = () => {
        // console.log("handleAudioDone() 1")
        if (audioData) {
            // console.log("handleAudioDone() has audio data 1")
            setIsScoring(true)
            const formData = new FormData();
            formData.append('audio', audioData);

            ScoreApi.getScore(dialogPromptSentenceList[dialogPromptIndexRef.current].sentence_id, formData)
                .then(response => {
                    setIsScoring(false)
                    // check if the response was successful
                    if (response.status >= 300) {
                        throw new Error('Scoring audio was not ok statuscode=' + response.status + " " + response.statusText);
                    }
                    return response.data;
                })
                .then(responseJson => {
                    responseJson.audioData = audioData;
                    setChatObjectList((previous) => [...previous, responseJson]);
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.message);
                    }
                })
        }
    }

    React.useEffect(() => {
        if (sentenceScoreBubbleDone) {
            setSentenceScoreBubbleDone(false);
        }
    }, [sentenceScoreBubbleDone])

    React.useEffect(() => {
        if (chatBubbleTryAgainDone) {
            setChatBubbleTryAgainDone(false)
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {/* Left-aligned X icon */}
                    <IconButton onClick={handleCancelClick}>
                        <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>

                    {/* Center-aligned buttons */}
                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }} spacing={2}>
                        <Button variant="contained" onClick={handleNextWordClick} className="secondaryButton" >
                            No, next sentence
                        </Button>
                        <Button variant="contained" onClick={handleTryAgainClick} >
                            Yes, Try Again
                        </Button>
                    </Stack>
                </Stack>

            )
        }
    }, [chatBubbleTryAgainDone]);

    const handleNextWordClick = () => {
        updateTryAgainClickedList(false)
    }
    const handleTryAgainClick = () => {
        updateTryAgainClickedList(true)
    }

    const updateTryAgainClickedList = (tryAgain: boolean) => {
        const index = chatObjectList.length - 1;
        setTryAgainClickedList(previous => {
            const updatedArray = [...previous];
            updatedArray[index] = tryAgain;
            return updatedArray;
        })
    }

    React.useEffect(() => {
        // continue to next sentence
        if (chatBubbleContinueDone) {
            setChatBubbleContinueDone(false)
            setChatBubbleDone(true)
            if (dialogPromptIndexRef.current + 1 >= dialogPromptSentenceList.length) {
                // no more sentences
                setDialogPromptComplete(dialogPrompt.id)
                setDialogSentenceDone(true)
            } else if (dialogPrompt.class_name == 'DialogPromptConversationSpeakOnly' && dialogPromptIndexRef.current + 2 < dialogPromptSentenceList.length) {
                // only speak every other sentence
                dialogPromptIndexRef.current += 2;
                setDialogPromptIndex(dialogPromptIndexRef.current);
            } else {
                // standard next sentence
                dialogPromptIndexRef.current += 1;
                setDialogPromptIndex(dialogPromptIndexRef.current);
            }
        }
    }, [chatBubbleContinueDone])

    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

    const handleCancelClick = () => {
        setOpenCancelDialog(true);
    };

    const handleCancelDialogConfirm = () => {
        setOpenCancelDialog(false);
        setChatBubbleContinueDone(true);
    };

    const handleCancelDialogCancel = () => {
        setOpenCancelDialog(false);
    };


    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                showAvatar={showAvatar}
                chatBubbleContainerRef={chatBubbleContainerRef}
                setChatBubbleDone={setChatBubbleDone}
            />
            {chatObjectList.map((chatObject, index) => {
                if ('dialog_prompt_id' in chatObject && 'sentence_id' in chatObject) {
                    // this is IDialogPromptSentence object
                    const sentenceIndex = dialogPromptSentenceList.findIndex((sentence) => sentence.id === chatObject.id);
                    let sentenceText = chatObject.sentence_text;
                    if (dialogPrompt.class_name == "DialogPromptConversationReadWithBlankSpeak") {
                        const dialogPromptRBS = dialogPrompt as DialogPromptConversationReadWithBlankSpeak;
                        const sentenceTextAlternate = dialogPromptRBS.dialog_prompt_sentence_list[sentenceIndex].sentence_text_alternate;
                        if (sentenceTextAlternate !== undefined) {
                            sentenceText = sentenceTextAlternate.replace(/\*/g, '__') // Replace all asterisks with underscores
                        } else {
                            sentenceText = 'ERROR0'
                        }
                    } else if (dialogPrompt.class_name == "DialogPromptConversationSpeakOnly") {
                        // this dialog is to speak every other sentence with no prompting.
                        if (sentenceIndex % 2 !== 0) {
                            sentenceText = sentenceText.replace(/./g, '_');
                        }
                    }
                    return <Box key={chatObject.id} display="inline-block">
                        <Box display="inline-flex" width="70px" /> {/* This acts as a spacer */}

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent='flex-start'
                            key={sentenceIndex}
                            style={{
                                margin: '1em',
                                display: 'inline-flex',
                                backgroundColor: sentenceIndex % 2 === 0 ? '#FFFFFF' : '#F2F2F2', // Example color condition
                            }} // Add margin to create space between Stacks
                            className='border-dark'
                        >
                            <Box sx={{ borderRight: '1.5px solid rgba(82, 99, 125, 0.8)', pr: '16px', display: 'flex', alignItems: 'center' }}>
                                {sentenceIndex % 2 === 0 && (<>
                                    <Typography sx={{
                                        fontSize: '36px',
                                        pl: '16px',
                                    }}>A</Typography>
                                </>)}
                                {sentenceIndex % 2 !== 0 && (<>
                                    <Typography sx={{
                                        fontSize: '36px',
                                        pl: '16px',
                                    }}>B</Typography>
                                </>)}

                            </Box>
                            {(dialogPrompt.class_name == "DialogPromptConversationListenSpeak" || dialogPrompt.class_name == "DialogPromptConversationListenWithoutCharacter") && (
                                <IconButton onClick={(event) => handleSpeakerClick(sentenceIndex)}>
                                    <img src={SpeakerBlueIcon} style={{ width: '24px', height: '24px' }} />
                                </IconButton>
                            )}
                            {dialogPrompt.class_name != "DialogPromptConversationListenWithoutCharacter" && (
                                <div className={kaiTiFontClass.customFont} style={{ fontSize: '36px', margin: 0, padding: 0, lineHeight: 0.9 }} >
                                    <span>{sentenceText}</span>
                                </div>
                            )}
                        </Stack>
                    </Box>
                } else if ('score' in chatObject && 'likely_text' in chatObject) {
                    // this is a ISentenceScore result object 
                    const sentenceScore = chatObject as ISentenceScore;

                    return <div key={sentenceScore.id}>
                        <ChatBubble
                            isTeacher={!dialogPrompt.is_teacher}
                            message={sentenceScore.text}
                            sentenceScore={sentenceScore}
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={setSentenceScoreBubbleDone}
                        />
                        {sentenceScoreAllGood(sentenceScore) ? (
                            <ChatBubble
                                isTeacher={true}
                                message={dialogPromptClassNameContainsProp(dialogPrompt.class_name, "yes_response") ? (dialogPrompt as DialogPromptConversationRelated).yes_response : "ERROR1"}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleContinueDone}
                            />
                        ) : sentenceScoreAnyFailed(sentenceScore) ? (
                            <ChatBubble isTeacher={dialogPrompt.is_teacher}
                                message={dialogPromptClassNameContainsProp(dialogPrompt.class_name, "no_response") ? (dialogPrompt as DialogPromptConversationRelated).no_response : "ERROR2"}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={(done) => {
                                    // setChatBubbleTryAgainDone(false);
                                    setChatBubbleDone(true);
                                    // set null, to skip this index
                                    setTryAgainClickedList((previous) => [...previous, null]);
                                }}
                            />
                        ) : (
                            <ChatBubble isTeacher={dialogPrompt.is_teacher}
                                message={"You're on the right track!"}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={(done) => setChatBubbleTeacherResponseDone(previous => {
                                    const updatedArray = [...previous];
                                    updatedArray[index] = done;
                                    return updatedArray;
                                })}
                            />
                        )}
                        {chatBubbleTeacherResponseDone[index] && (
                            <ChatBubble
                                isTeacher={true}
                                message="Would you like to try again?"
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleTryAgainDone}
                            />
                        )}
                        {tryAgainClickedList[index] === true && (
                            <ChatBubble
                                isTeacher={false}
                                message="I will try again"
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                            />
                        )}
                        {tryAgainClickedList[index] === false && (
                            <ChatBubble
                                isTeacher={false}
                                message="No, thank you"
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleContinueDone}
                            />

                        )}
                    </div>;
                } else {
                    return <div key={index}>Unknown object {JSON.stringify(chatObject)}</div>;
                }
            })}

            {/* Confirmation Dialog */}
            <Dialog open={openCancelDialog} onClose={handleCancelDialogCancel}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirm Skip</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Are you sure you want to skip this exercise?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleCancelDialogCancel} color="primary" sx={{ color: '#787878' }}>
                        Keep trying
                    </Button>
                    <Button onClick={handleCancelDialogConfirm} color="primary" sx={{ color: '#787878' }}>
                        Skip
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Add Sentence Confirmation Dialog */}
            <Dialog open={addSentenceDialogOpen} onClose={handleClose}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirmation</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Do you want to add this sentence to Self-Study?
                        <p>
                            {dialogPromptSentenceList[dialogPromptIndexRef.current] && dialogPromptSentenceList[dialogPromptIndexRef.current].sentence_text}
                        </p>
                    </DialogContentText>
                    {/* Display success message inside the Dialog */}
                    {addSentenceSuccessMessage && <p>{addSentenceSuccessMessage}</p>}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleClose} color="primary" sx={{ color: '#787878' }}>
                        {addSentenceSuccessMessage.length == 0 ? "Cancel" : "Close"}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" disabled={addSentenceSuccessMessage.length > 0} sx={{ color: '#787878' }}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack >
    );
};

export default DialogPromptConversationRelatedComponent;
